<footer>
  <div class="footer__info">
    <div class="about__lists">
      <ul class="about__list contact-info">
        <li class="font--special">
          <a href="mailto:schumacher.y@northeastern.edu"
            ><i class="far fa-envelope"></i> schumacher.y@northeastern.edu</a
          >
        </li>
        <li class="font--special">
          <a href="tel:6466680656"
            ><i class="fas fa-phone"></i> 646-668-0656</a
          >
        </li>
      </ul>
      <ul class="about__list">
        <li class="font--special">
          <a href="./pdf/resume.pdf" target="_blank">Resume</a>
        </li>
        <li>
          <a href="https://twitter.com/yuriko_a_s" target="_blank"
            ><i class="fab fa-twitter"></i
          ></a>
        </li>
        <li>
          <a href="https://github.com/Yuriko-Schumacher" target="_blank"
            ><i class="fab fa-github"></i
          ></a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/in/yuriko-schumacher/?locale=en_US"
            target="_blank"
            ><i class="fab fa-linkedin"></i
          ></a>
        </li>
      </ul>
    </div>
  </div>
  <div class="credit">
    <p class="font--special">© Yuriko Schumacher 2022</p>
  </div>
</footer>